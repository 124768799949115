<template>
  <div :class="$style.dashboard">
    <div class="container mx-auto">
      <div :class="$style.logoContainer">
        <router-link to="/" class="logo">
          <img :src="require('@/assets/img/logo.png')" alt="" />
        </router-link>
      </div>

      <h1 :class="$style.headline">Files</h1>
      <div>
        <div :class="$style.tableContainer">
          <table :class="$style.productsTable">
            <thead :class="$style.tableHead">
              <tr>
                <th>rows</th>
                <th>matches</th>
                <th>matches (%)</th>
                <th>uploaded</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product of products" :key="product.id">
                <td>
                  <router-link :to="`dashboard/product/${product.id}`">
                    <div>{{ product.rows }}</div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="`dashboard/product/${product.id}`">
                    <div>{{ product.matchedCodesCount }}</div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="`dashboard/product/${product.id}`">
                    <div>{{ product.matchedPercentage }}%</div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="`dashboard/product/${product.id}`">
                    <div>{{ product.createdDate }}</div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div :class="$style.logoutContainer">
          <BaseButton @click.prevent="logout">Logout</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onAuthStateChanged, logout } from "@/api/authApi";
import { fetchUserProducts } from "@/api/productApi";
import { withAsync } from "@/helpers";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { API_STATUS } from "@/constants/apiStatus";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

export default {
  name: "Dashboard",
  data() {
    return {
      user: null,
      products: [],
      fetchProductsStatus: IDLE,
    };
  },
  async created() {
    const user = await onAuthStateChanged();
    if (!user) {
      this.$router.push("/");
      return;
    }
    this.user = user;
    this.fetchUserProducts(user);
  },
  computed: {
    ...apiStatusComputedFactory("fetchProductsStatus"),
  },
  methods: {
    async logout() {
      await logout();
      this.$router.push("/");
    },
    async fetchUserProducts(user) {
      this.fetchProductsStatus = PENDING;
      const { response, error } = await withAsync(fetchUserProducts, user.id);
      if (error) {
        console.error("error", error);
        this.fetchProductsStatus = ERROR;
        return;
      }
      this.products = response.docs.map((snap) => {
        const data = snap.data();
        return {
          ...data,
          rows: data.rows?.toLocaleString(),
          createdDate: new Intl.DateTimeFormat("en-US").format(
            data.createdAt.toDate()
          ),
        };
      });
      this.fetchProductsStatus = SUCCESS;
    },
  },
};
</script>
<style src="../apps/css/boostrap.min.css"></style>
<style lang="scss" module>
.dashboard {
  min-height: 100vh;
  margin-top: 120px;
  display: flex;
  justify-content: center;
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}

.headline {
  font-size: 1.25rem;
  font-weight: bold;
  border-bottom: 0.3rem solid #4ef7cf;
  padding: 0.2rem 0.5rem;
  margin-bottom: 2.5rem;
  display: inline-block;
}

.tableHead {
  background-color: #f8f8f8;
}

.tableContainer {
  max-height: 25rem;
  overflow-y: auto;
}

.productsTable tr {
  th,
  td {
    color: #373a3c;
    padding: 0.3rem 1.25rem;
    display: table-cell !important;
  }

  a {
    text-decoration: none;
    color: #373a3c;
  }
}

.logoutContainer {
  display: flex;
  justify-content: flex-end;
  margin: 2rem;
}
</style>